<template> 
  <div class="createdModules" v-if="clubVersion === 'v2'">
    <div class="container">
      <modal-upload :data="data"></modal-upload>
      <modal-categoria></modal-categoria>
      <div
        :class="{ editModuleClass: editModuleModal, hide: !editModuleModal }"
      >
        <div class="contentAdd" data-anima="top">
          <svg
            @click="closeEditModule"
            class="close"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
              stroke="#B5B9C5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="title title-v2" v-if="!createnewmodule">Editar Módulo</div>
          <div class="title title-v2" v-else>Criar Módulo</div>
          <br />
          <div class="spaceInputs">
            <b-form-group label="Nome do Módulo" label-for="name-module">
              <b-form-input
                v-model="editNameModule"
                placeholder="Nome do Módulo"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="spaceInputs">
            <b-form-group label="Capa do Módulo" label-for="capa">
              <p class="textDimenssion" v-if="!homeVertical">
                Proporção Recomendada: 16:9 - Tamanho Recomendado: 1250x747
              </p>
              <p class="textDimenssion" v-else>
                Proporção Recomendada: 5:7 - Tamanho Recomendado: 729x980
              </p>
              <div
                class="btn-openModal"
                @click="showModalUploadCapa"
                v-if="midiacapamodule === '' || midiacapamodule === null"
              >
                <p class="text">Clique e faça o Upload da Capa do Módulo</p>
              </div>
              <div
                class="btn-openModalPreview"
                @click="showModalUploadCapa"
                v-else
              >
                <img
                  class="imagePreviewCapa"
                  :src="midiacapamoduleurl"
                  :alt="midiacapamodule"
                />
              </div>
              <div class="space margin-none">
                <button class="btn-salvar remove" v-if="midiacapamodule !== '' && midiacapamodule !== null" @click="midiacapamodule = null, midiacapamoduleurl = null">
                  Remover Capa
                </button>
              </div>
            </b-form-group>
            <b-form-group label="Módulo Condicional" label-for="capa">
              <toggle-button :sync="true" v-model="condicionalModule" />
            </b-form-group>
            <b-form-group
              v-if="condicionalModule"
              label="Selecione a Turma que terá o Módulo"
              label-for="capa"
            >
              <multiselect
                noOptions="Nenhuma Turma Disponível"
                placeholder="Turmas"
                selectedLabel=""
                :deselectLabel="''"
                tagPlaceholder=""
                :selectLabel="''"
                track-by="id"
                :multiple="true"
                :taggable="false"
                :custom-label="customLabel"
                v-model="courseClassModule"
                :options="AllClass"
              >
              </multiselect>
            </b-form-group>
          </div>
          <div class="space">
            <button
              v-if="!createnewmodule"
              class="btn-salvar"
              @click="salvarEditarModule"
            >
              Salvar
            </button>
            <button v-else class="btn-salvar" @click="addNewModule">
              Salvar
            </button>
          </div>
        </div>
      </div>
      <b-row class="spaceWizzard">
        <b-col cols="12" md="3" class="pt-5">
          <div class="navigate">
            <div class="title">
              {{ nameCourse }}
              <button
                id="viewModuleLesson"
                class="viewModuleLesson"
                @click="viewCourse()"
              >
                Ver Curso
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.375 8.75L12.5 5.625L9.375 2.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/' + cursoAtual"
              class="removeLink"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H9V16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H11V14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V11Z"
                      fill="#333333"
                    />
                  </svg>
                  Dados do Curso
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/' + cursoAtual + '/module'"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z"
                      fill="#333333"
                    />
                  </svg>
                  Módulos e Aulas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/class/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="26"
                    height="22"
                    viewBox="0 0 26 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                      stroke="#333333"
                      stroke-width="2.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Turmas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/create-certificates/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
                      stroke="#333333"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Certificado
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/vendas/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  Vendas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>

            <div class="spaceBtn">
              <toggle-button
                :sync="true"
                @change="enventSwitchStatus"
                :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                v-model="myDataVariable"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="9" class="pt-5" data-anima="top">
          <div class="containerWizzard">
            <div class="title title-v2">
              Gerenciamento de Módulo
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5M11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11C9 9.89543 9.89543 9 11 9Z"
                  stroke="#333333"
                  stroke-width="2.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="containerWizzardInputs" id="step10" v-if="!loading">
            <draggable
              :list="AllModules"
              :disabled="!enabled"
              class="list-group"
              ghost-class="ghost"
              @end="onEnd"
            >
              <div
                class="spaceModuleCard"
                v-for="(modules, index) in AllModules"
                :key="modules.id"
                :data-id="modules.id"
              >
                <div
                  class="actionModule"
                  :id="'firstmodule' + index"
                >
                  <div class="actionModule">
                    <div
                      class="titleModule"
                      :id="'modulename' + index"
                      @click="editAulas(modules.id)"
                      v-if="modules.cover !== null"
                    >
                      <img
                        class="imgModule"
                        width="50"
                        :src="modules.cover.cdn_url"
                        alt="imagem"
                      />
                    </div>
                    <div
                      class="titleModule title-v2"
                      :id="'modulename' + index"
                      @click="editAulas(modules.id)"
                    >
                      {{ modules.title }}
                    </div>
                  </div>
                  <div class="actions" style="
                      width: max-content;
                      display: flex;
                      align-items: center;
                  ">
                    <svg
                      width="19"
                      height="5"
                      viewBox="0 0 19 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.83667 3.83643C10.389 3.83643 10.8367 3.38871 10.8367 2.83643C10.8367 2.28414 10.389 1.83643 9.83667 1.83643C9.28439 1.83643 8.83667 2.28414 8.83667 2.83643C8.83667 3.38871 9.28439 3.83643 9.83667 3.83643Z"
                        stroke="#C4C4C4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.8367 3.83643C17.389 3.83643 17.8367 3.38871 17.8367 2.83643C17.8367 2.28414 17.389 1.83643 16.8367 1.83643C16.2844 1.83643 15.8367 2.28414 15.8367 2.83643C15.8367 3.38871 16.2844 3.83643 16.8367 3.83643Z"
                        stroke="#C4C4C4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.83667 3.83643C3.38895 3.83643 3.83667 3.38871 3.83667 2.83643C3.83667 2.28414 3.38895 1.83643 2.83667 1.83643C2.28439 1.83643 1.83667 2.28414 1.83667 2.83643C1.83667 3.38871 2.28439 3.83643 2.83667 3.83643Z"
                        stroke="#C4C4C4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <b-dropdown
                      id="dropdown-dropright"
                      dropright
                      text=""
                      class="m-2"
                    >
                      <b-dropdown-item @click="editModule(modules)"
                        >Editar Módulo</b-dropdown-item
                      >
                      <b-dropdown-item @click="editAulas(modules.id)"
                        >Editar Aulas</b-dropdown-item
                      >
                      <b-dropdown-item @click="viewModule(modules.id)"
                        >Ver Módulo</b-dropdown-item
                      >
                      <b-dropdown-item @click="deleteModule(modules.id)"
                        >Deletar Módulo</b-dropdown-item
                      >
                    </b-dropdown>
                    <button
                      id="viewModuleLesson"
                      class="viewModuleLesson"
                      @click="viewModule(modules.id)"
                    >
                      Ver Módulo
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.375 8.75L12.5 5.625L9.375 2.5"
                          stroke="#C4C4C4"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                          stroke="#C4C4C4"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </draggable>
            <div class="spaceInputs">
              <b-form-group class="paddingNewModule">
                <div
                  class="btn-newModule"
                  @click="openModalToaddNew()"
                  id="step11"
                >
                  <p class="text">Adicionar mais um Módulo</p>
                </div>
              </b-form-group>
            </div>
          </div>
            <div class="d-flex justify-content-center mt-5" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-col>
      </b-row>
    </div>
  </div> 
  <div class="createdModules" v-else>
    <div class="container">
      <modal-upload :data="data"></modal-upload>
      <modal-categoria></modal-categoria>
      <div
        :class="{ editModuleClass: editModuleModal, hide: !editModuleModal }"
      >
        <div class="contentAdd" data-anima="top">
          <svg
            @click="closeEditModule"
            class="close"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 1.42603L1 16.426M1 1.42603L16 16.426"
              stroke="#B5B9C5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="title" v-if="!createnewmodule">Editar Módulo</div>
          <div class="title" v-else>Criar Módulo</div>
          <br />
          <div class="spaceInputs">
            <b-form-group label="Nome do Módulo" label-for="name-module">
              <b-form-input
                v-model="editNameModule"
                placeholder="Nome do Módulo"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="spaceInputs">
            <b-form-group label="Capa do Módulo" label-for="capa">
              <p class="textDimenssion" v-if="!homeVertical">
                Proporção Recomendada: 16:9 - Tamanho Recomendado: 1250x747
              </p>
              <p class="textDimenssion" v-else>
                Proporção Recomendada: 5:7 - Tamanho Recomendado: 729x980
              </p>
              <div
                class="btn-openModal"
                @click="showModalUploadCapa"
                v-if="midiacapamodule === '' || midiacapamodule === null"
              >
                <p class="text">Clique e faça o Upload da Capa do Módulo</p>
              </div>
              <div
                class="btn-openModalPreview"
                @click="showModalUploadCapa"
                v-else
              >
                <img
                  class="imagePreviewCapa"
                  :src="midiacapamoduleurl"
                  :alt="midiacapamodule"
                />
              </div>
              <div class="space margin-none">
                <button class="btn-salvar remove" v-if="midiacapamodule !== '' && midiacapamodule !== null" @click="midiacapamodule = null, midiacapamoduleurl = null">
                  Remover Capa
                </button>
              </div>
            </b-form-group>
            <b-form-group label="Módulo Condicional" label-for="capa">
              <toggle-button :sync="true" v-model="condicionalModule" />
            </b-form-group>
            <b-form-group
              v-if="condicionalModule"
              label="Selecione a Turma que terá o Módulo"
              label-for="capa"
            >
              <multiselect
                noOptions="Nenhuma Turma Disponível"
                placeholder="Turmas"
                selectedLabel=""
                :deselectLabel="''"
                tagPlaceholder=""
                :selectLabel="''"
                track-by="id"
                :multiple="true"
                :taggable="false"
                :custom-label="customLabel"
                v-model="courseClassModule"
                :options="AllClass"
              >
              </multiselect>
            </b-form-group>
          </div>
          <div class="space">
            <button
              v-if="!createnewmodule"
              class="btn-salvar"
              @click="salvarEditarModule"
            >
              Salvar
            </button>
            <button v-else class="btn-salvar" @click="addNewModule">
              Salvar
            </button>
          </div>
        </div>
      </div>
      <b-row class="spaceWizzard">
        <b-col cols="12" md="3" class="pt-5">
          <div class="navigate">
            <div class="title">
              {{ nameCourse }}
              <button
                id="viewModuleLesson"
                class="viewModuleLesson"
                @click="viewCourse()"
              >
                Ver Curso
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.375 8.75L12.5 5.625L9.375 2.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                    stroke="#C4C4C4"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/' + cursoAtual"
              class="removeLink"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H9V16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H11V14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V11Z"
                      fill="#333333"
                    />
                  </svg>
                  Dados do Curso
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/' + cursoAtual + '/module'"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z"
                      fill="#333333"
                    />
                  </svg>
                  Módulos e Aulas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/class/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="26"
                    height="22"
                    viewBox="0 0 26 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                      stroke="#333333"
                      stroke-width="2.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Turmas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>
            <router-link
              :to="'/create-certificates/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
                      stroke="#333333"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Certificado
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <!-- <div class="lineDiv"></div>
            <router-link
            :to="'/config_curso/emails/' + cursoAtual + ''"
            class="removeLink"
            :class="{ disableLink: existParans }"
          >
            <div class="spaceNav">
              <div class="text">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z"
                    fill="#333333"
                  />
                </svg>
                E-mails
              </div>
              <img src="@/assets/icons/arrow-navigate.svg" />
            </div>
          </router-link> -->
            <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/vendas/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="#333333"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  Vendas
                </div>
                <img src="@/assets/icons/arrow-navigate.svg" />
              </div>
            </router-link>
            <div class="lineDiv"></div>

            <div class="spaceBtn">
              <toggle-button
                :sync="true"
                @change="enventSwitchStatus"
                :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                v-model="myDataVariable"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="9" class="pt-5" data-anima="top">
          <div class="containerWizzard">
            <div class="title">
              Gerenciamento de Módulo
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5M11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11C9 9.89543 9.89543 9 11 9Z"
                  stroke="#333333"
                  stroke-width="2.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="containerWizzardInputs" id="step10" v-if="!loading">
            <draggable
              :list="AllModules"
              :disabled="!enabled"
              class="list-group"
              ghost-class="ghost"
              @end="onEnd"
            >
              <div
                class="spaceModuleCard"
                v-for="(modules, index) in AllModules"
                :key="modules.id"
                :data-id="modules.id"
              >
                <div
                  class="actionModule"
                  :id="'firstmodule' + index"
                >
                  <div class="actionModule">
                    <div
                      class="titleModule"
                      :id="'modulename' + index"
                      @click="editAulas(modules.id)"
                      v-if="modules.cover !== null"
                    >
                      <img
                        class="imgModule"
                        width="50"
                        :src="modules.cover.cdn_url"
                        alt="imagem"
                      />
                    </div>
                    <div
                      class="titleModule"
                      :id="'modulename' + index"
                      @click="editAulas(modules.id)"
                    >
                      {{ modules.title }}
                    </div>
                  </div>
                  <div class="actions" style="
                      width: max-content;
                      display: flex;
                      align-items: center;
                  ">
                    <svg
                      width="19"
                      height="5"
                      viewBox="0 0 19 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.83667 3.83643C10.389 3.83643 10.8367 3.38871 10.8367 2.83643C10.8367 2.28414 10.389 1.83643 9.83667 1.83643C9.28439 1.83643 8.83667 2.28414 8.83667 2.83643C8.83667 3.38871 9.28439 3.83643 9.83667 3.83643Z"
                        stroke="#C4C4C4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.8367 3.83643C17.389 3.83643 17.8367 3.38871 17.8367 2.83643C17.8367 2.28414 17.389 1.83643 16.8367 1.83643C16.2844 1.83643 15.8367 2.28414 15.8367 2.83643C15.8367 3.38871 16.2844 3.83643 16.8367 3.83643Z"
                        stroke="#C4C4C4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.83667 3.83643C3.38895 3.83643 3.83667 3.38871 3.83667 2.83643C3.83667 2.28414 3.38895 1.83643 2.83667 1.83643C2.28439 1.83643 1.83667 2.28414 1.83667 2.83643C1.83667 3.38871 2.28439 3.83643 2.83667 3.83643Z"
                        stroke="#C4C4C4"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <b-dropdown
                      id="dropdown-dropright"
                      dropright
                      text=""
                      class="m-2"
                    >
                      <b-dropdown-item @click="editModule(modules)"
                        >Editar Módulo</b-dropdown-item
                      >
                      <b-dropdown-item @click="editAulas(modules.id)"
                        >Editar Aulas</b-dropdown-item
                      >
                      <b-dropdown-item @click="viewModule(modules.id)"
                        >Ver Módulo</b-dropdown-item
                      >
                      <b-dropdown-item @click="deleteModule(modules.id)"
                        >Deletar Módulo</b-dropdown-item
                      >
                    </b-dropdown>
                    <button
                      id="viewModuleLesson"
                      class="viewModuleLesson"
                      @click="viewModule(modules.id)"
                    >
                      Ver Módulo
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.375 8.75L12.5 5.625L9.375 2.5"
                          stroke="#C4C4C4"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                          stroke="#C4C4C4"
                          stroke-width="1.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </draggable>
            <div class="spaceInputs">
              <b-form-group class="paddingNewModule">
                <div
                  class="btn-newModule"
                  @click="openModalToaddNew()"
                  id="step11"
                >
                  <p class="text">Adicionar mais um Módulo</p>
                </div>
              </b-form-group>
            </div>
          </div>
            <div class="d-flex justify-content-center mt-5" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import CategoryService from "@/services/resources/CategoryService";
import CourseService from "@/services/resources/CourseService";
import Vue2Filters from "vue2-filters";
const serviceCourse = CourseService.build();
const serviceCategory = CategoryService.build();
import notify from "@/services/libs/notificacao";
import {ToggleButton} from "vue-js-toggle-button";
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
export default {
  mixins: [Vue2Filters.mixin],
  components: {
    ModalUpload: () => import("@/components/ModalUpload.vue"),
    ModalCategoria: () => import("@/components/ModalCategoria.vue"),
    draggable,   
    Multiselect,
    ToggleButton,
  },
  data() {
    return {
      condicionalModule: false,
      courseClassModule: [],
      nameCourse: "",
      nameModulo: "",
      resumoCourse: "",
      descricaoCourse: "",
      categoryCourse: "",
      step: 0,
      AllCategory: 1,
      data: "introducao",
      midiaintroducao: "",
      midiacapa: "",
      midiaaulas: "",
      module_id_edit: "",
      AllModules: [],
      totalModules: "",
      nameAula: "",
      descricaoAula: "",
      AllAulas: "",
      editNameModule: "",
      editModuleModal: false,
      editActualModule: "",
      cursoAtual: "",
      moduleAtual: "",
      myDataVariable: true,
      existParans: true,
      enabled: true,
      dragging: false,
      moduleLiberation: false,
      dataInit: "",
      dataFim: "",
      createnewmodule: false,
      midiacapamodule: null,
      midiacapamoduleurl: null,
      homeVertical: false,
      loading: false,
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },

  methods: {
    customLabel({ title }) {
      return `${title}`;
    },
    getClass() {
        serviceCourse
          .read(this.cursoAtual + "/class")
          .then((resp) => {
            //console.log("get class", resp);
            this.AllClass = resp;
          })
          .catch((err) => {
            //console.log(err);
          });
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        //console.log("get modules", resp);
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },
    viewModule(moduleId) {
      serviceCourse
        .read(this.cursoAtual + "/module/" + moduleId + "/lesson")
        .then((resp) => {
          if (resp.length === 0) {
            notify("erro", "Nenhuma Aula no Módulo!");
            return false;
          }
          let routeData = this.$router.resolve(
            "/curso/" +
              this.cursoAtual +
              "/modulo/" +
              moduleId +
              "/aula/" +
              resp[0].id
          );
          window.open(routeData.href, "_blank");
        });
    },
    enventSwitchStatus() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      let data = {
        id: this.cursoAtual,
        status: xxxStatus,
      };
      //console.log(data);
      this.loading = true;
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then((resp) => {
          this.loading = false;
          //console.log("update course status", resp);
          this.getCourse();
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    onEnd: function (e) {
      //console.log("event", e);

      var array = [];
      var lessons = document.querySelectorAll(".spaceModuleCard");
      for (let i = 0; i < lessons.length; i++) {
        const lessonId = lessons[i].getAttribute("data-id");
        array.push(parseInt(lessonId));
      }
      let data = {
        id: this.cursoAtual + "/module/order",
        array,
      };

      this.loading = true;
      serviceCourse
        .postIDArray(data)
        .then((resp) => {
          //console.log("order module", resp, array);
          this.getModules();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    editAulas(idModule) {
      this.$router.push(
        "/config_curso/" + this.cursoAtual + "/module/" + idModule + "/lesson"
      );
    },
    salvarEditarModule() {
      //console.log(this.condicionalModule, this.courseClassModule);
      let data = {
        id: this.cursoAtual + "/module/" + this.editActualModule,
        title: this.editNameModule,
        cover: this.midiacapamodule,
        is_conditional: false,
      };

      if(this.condicionalModule){
        var metas = this.courseClassModule;
        var result = [];
        if (metas.length) {
          metas.forEach((element) => {
            result.push(element.id);
          });
        }else{
          notify("erro", "Selecione uma Turma!");
          return
        }

        data.course_classes = result;
        data.is_conditional = true;
      }else{
        data.course_classes = [];
        data.is_conditional = false;
      }

      this.loading = true;
      serviceCourse
        .postID(data)
        .then((resp) => {
          //console.log("edit module", resp);
          (this.editNameModule = ""),
            (this.editModuleModal = false),
            (this.editActualModule = ""),
            (this.createnewmodule = true);
          notify("sucesso", "Módulo editado com Sucesso!");
          this.onEnd();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Editar Módulo!");
          this.loading = false;
        });
    },
    editModule(data) {
      //console.log(data);
      this.editActualModule = "";
      this.editNameModule = "";
      this.midiacapamodule = null;
      this.midiacapamoduleurl = null;

      
      this.condicionalModule = data.is_conditional;
      if(data.course_classes !== null){
        var myArray = data.course_classes;
        var AllClass = this.AllClass;
        var result = [];
        for (let i = 0; i < myArray.length; i++) {
          const element = myArray[i];
          var filter = AllClass.filter(x => x.id === myArray[i]);
          result.push(filter[0]);
        }
        //console.log(result);
        this.courseClassModule = result;
      }else{
         this.courseClassModule = [];
      }
      
      this.editActualModule = data.id;
      this.editNameModule = data.title;
      if (data.cover !== null) {
        this.midiacapamodule = data.cover.id;
        this.midiacapamoduleurl = data.cover.cdn_url;
      }
      this.editModuleModal = true;
      this.createnewmodule = false;
    },
    timeConvert(num) {
      var hours = Math.floor(num / 60);
      var minutes = num % 60;
      return hours + ":" + minutes;
    },
    closeEditModule() {
      this.editModuleModal = false;
      this.createnewmodule = true;
    },
    deleteModule(id) {
      this.loading = true;
      serviceCourse
        .destroy(this.cursoAtual + "/module/" + id)
        .then((resp) => {
          //console.log("delete module", resp);
          this.getModules();
          notify("sucesso", "Módulo deletado com Sucesso!");
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
          this.loading = false;
        });
    },
    openModalToaddNew() {
      this.editActualModule = "";
      this.editNameModule = "";
      this.editModuleModal = true;
      this.createnewmodule = true;
    },
    addNewModule(firstName) {
      var dataTitle = this.editNameModule;

      if (dataTitle === "" || dataTitle === null || dataTitle === undefined) {
        dataTitle = firstName;
      }

      let data = {
        id: this.cursoAtual + "/module",
        title: dataTitle,
        cover: this.midiacapamodule,
        is_conditional: false,
      };

      if(this.condicionalModule){
        var metas = this.courseClassModule;
        var result = [];
        if (metas.length) {
          metas.forEach((element) => {
            result.push(element.id);
          });
        }else{
          notify("erro", "Selecione uma Turma!");
          return
        }

        data.course_classes = result;
        data.is_conditional = true;
      }else{
        data.course_classes = [];
        data.is_conditional = false;
      }

      this.loading = true;
      serviceCourse
        .postID(data)
        .then((resp) => {
          //console.log("created module", resp);
          (this.editNameModule = ""),
            (this.editModuleModal = false),
            (this.editActualModule = ""),
            notify("sucesso", "Módulo criado com Sucesso!");
          this.getModules();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao Criar Módulo!");
          this.loading = false;
        });
    },
    getModules() {
      this.loading = true;
      serviceCourse
        .read(this.cursoAtual + "/module")
        .then((resp) => {
          //console.log("get modules", resp);
          this.AllModules = resp;
          this.totalModules = resp.length;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    openModalCategoria() {
      this.$root.$emit("bv::show::modal", "modal-categoria", "#btnShow");
    },
    showModalUploadIntro() {
      this.data = "introducao";
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadCapa() {
      this.$root.$emit("uploadcapamodulo");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUpload() {
      this.data = "aulas";
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    hideModalUpload() {
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");
    },
    getCategory() {
      this.loading = true;
      serviceCategory
        .search()
        .then((resp) => {
          //console.log("category", resp);
          this.categoryCourse = resp[0].id;
          this.AllCategory = resp;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getCourse() {
      let data = {
        id: this.cursoAtual,
      };
      this.loading = true;
      serviceCourse
        .read(data)
        .then((resp) => {
          //console.log("get course", resp);
          this.nameCourse = resp.title;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    async getHomeVertical() {
      let resp = await this.$store.dispatch("fetchMetas", ["home_vertical"])
      let data = resp.home_vertical;
      if (data === "off" || data === null) {
        this.homeVertical = false;
      } else {
        this.homeVertical = true;
      }
      this.loading = false;
    },
    getCourseModule() {
      let idCourse = this.$route.params.curso;
      let tath = this;
      if (idCourse === null || idCourse === undefined || idCourse === "") {
        this.$router.push("/config_curso");
      } else {
        tath.cursoAtual = idCourse;
        this.loading = true;
        serviceCourse
          .read(tath.cursoAtual + "/module")
          .then((resp) => {
            //console.log("get course idCourse", resp);
            if (resp.length === 0 || resp.length === "0") {
              tath.addNewModule("Módulo 1");
            } else {
              this.getModules();
            }
            this.getCourse();
            this.getClass();
            this.loading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getCourseModule();
    this.getHomeVertical();
    this.$root.$on("midiaaulas", (data) => {
      this.midiaaulas = parseInt(data);
    });
    this.$root.$on("newuploadcapamodulo", (data) => {
      //console.log(data, "chegou aquiiiiii");
      this.midiacapamodule = data.id;
      this.midiacapamoduleurl = data.url;
    });
    this.$root.$on("stepconcluirmodulo", (data) => {
      this.editAulas(this.AllModules[0].id);
    });
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.title-v2{
  font-family: "Montserrat Alternates" !important; 
  font-style: normal !important;
  font-weight: 800 !important;
}

.createdModules {
  .removeLink {
    text-decoration: none;
  }
  .navigate {
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(33, 51, 210, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        border: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #2133d2;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }

  .editModuleClass {
    position: fixed;
    background: var(--fundomodal);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    .contentAdd {
      z-index: 99999;
      position: absolute;
      padding: 30px;
      overflow-y: auto;
      right: 0;
      top: 0;
      width: 450px;
      height: 100%;
      background: var(--backgroundcolor);
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .margin-none{
        margin: 10px 0 !important;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
        .remove {
          background: red !important;
        }
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    padding-top: 55px;
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 8px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Montserrat !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        .titleModule {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
          line-height: 3em;
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Montserrat !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: var(--backgroundcolor);
        border: 0.5px solid var(--bordercolor);
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px 15px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }
    .containerx {
      width: 98%;
    }
    .containerWizzard {
      text-align: center;
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .spaceInputs {
    margin-bottom: 30px;
    .textInfo {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #81858e;
      margin-top: 5px;
    }
    .btn-criar {
      background: var(--maincolor);
      border: 1px solid var(--maincolor);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      width: 100%;
      height: 55px;
      margin-top: 40px;
    }
    .btn-openModal {
      background: var(--maincolortrans);
      border: 2px dashed var(--maincolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      cursor: pointer;
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
        display: flex;
        justify-content: center;
        margin-top: 17px;
      }
    }
    .gerenciarCat {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
      position: absolute;
      right: 25em;
      cursor: pointer;
    }
    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #6d767e;
      overflow: hidden !important;
      resize: none !important;
    }
    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }
    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
    label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
  }
}

.imgModule {
  margin-top: 5px;
  width: 75px;
  margin-right: 10px;
  border-radius: 3px;
}
</style>
